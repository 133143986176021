import esApiV3 from './esapiv3.service';
import exceptionService from './exception.service';

const api = {
  communicationLogs: () => 'telephony/communication-log',
};

export async function getCommunicationLogs(query) {
  try {
    const res = await esApiV3.get(api.communicationLogs(), {
      params: {
        ...query,
      },
    });
    return res.data;
  } catch (ex) {
    exceptionService.showCustomError(
      ex,
      'An error occurred while trying to get communications',
    );
  }
}
