const module = {
  namespaced: true,
};

module.state = {
  token: null,
  recaptcha: null,
};

module.mutations = {
  SET_TOKEN(state, token) {
    state.token = token;
  },
  SET_RECAPTCHA(state, recaptcha) {
    state.recaptcha = recaptcha;
  },
};

module.actions = {
  async setAuth({ commit }, token, recaptchaToken) {
    commit('SET_TOKEN', token);
    commit('SET_RECAPTCHA', recaptchaToken);
  },
};

module.getters = {};

export default module;
