import esApiV3 from './esapiv3.service';
import exception from './exception.service';

const api = {
  customerForUser: () => 'customers/self',
  updateCustomer: (customerId) => `customers/${customerId}`,
  proofOfValue: () => 'customers/self/proofOfValueMetrics',
};

export async function getCustomerForUser() {
  try {
    const res = await esApiV3.get(api.customerForUser());
    return res.data;
  } catch (ex) {
    exception.showCustomError(
      ex,
      'An error occurred while trying to get current customer',
    );
  }
}

export async function updateCustomer(customerId, customerData) {
  const res = await esApiV3.patch(api.updateCustomer(customerId), customerData);
  return res.data;
}

export async function getProofOfValueMetrics(locationIds) {
  const res = await esApiV3.get(api.proofOfValue(), {
    params: {
      locationIds,
    },
  });
  return res.data;
}
