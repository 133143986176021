import {
  getTask,
  getTaskById,
} from '@/service/vendor-auto-communication.service';

const module = {
  namespaced: true,
};

module.state = {
  task: null,
};

module.mutations = {
  SET_TASK(state, task) {
    state.task = task;
  },
};

module.actions = {
  async getTaskInfo({ commit }, { token, recaptchaToken }) {
    const result = await getTask(token, recaptchaToken);
    commit('SET_TASK', result);
  },

  async getTaskInfoById({ commit }, { token, recaptchaToken, id }) {
    const result = await getTaskById(token, recaptchaToken, id);
    commit('SET_TASK', result);
  },
};

module.getters = {};

export default module;
