import esApiV3 from '@/service/esapiv3.service';

const api = {
  getAssetIdByQrCodeId: (qrCodeId) => `asset-qr-code/${qrCodeId}/asset-id`,
};

/**
 * @param { string } qrCodeId
 * @returns { Promise<string | null> }
 */
export async function getAssetIdByQrCodeId(qrCodeId) {
  try {
    const res = await esApiV3.get(api.getAssetIdByQrCodeId(qrCodeId));
    return res.data;
  } catch (ex) {
    console.error(ex);
    return null;
  }
}
