import { defineStore } from 'pinia';
import config from '@/config';
import authService from '@/service/auth.service';
import userProfileService from '@/service/user-profile.service';
import { ability } from '@/plugins/ability';
import { useCustomerStore } from './customerStore';

export const useAuthStore = defineStore('auth', {
  state: () => ({
    configMode: config.mode,
    configVersion: config.version,
    userInfo: {},
    rolesAndRanks: [],
    isVendorWithUpdatePermissions: false,
    routeName: null,
  }),
  actions: {
    async getUserInfo() {
      const results = await userProfileService.getUserInfo();
      this.userInfo = results;
    },
    async loadUserCaslRules() {
      const rules = await authService.getUserCaslRules();
      ability.update(rules || []);
    },
    async unlinkLoginProvider(provider = '') {
      provider = provider.toLowerCase();
      await authService.unlinkLoginProvider(provider);
      const { userInfo } = this.state;
      userInfo.identities = userInfo.identities?.filter(
        (i) => i.identityProvider !== provider,
      );
      this.userInfo = userInfo;
    },
    async getAllRolesAndRanks() {
      const results = await authService.getRolesAndRanks();
      this.rolesAndRanks = results;
    },
    updateCaslPermissionState({ casl, value }) {
      this[casl] = value;
    },
    setRouteName(routeName) {
      this.routeName = routeName;
    },
  },
  getters: {
    hasUserInfo: (state) =>
      state.userInfo != null && Object.keys(state.userInfo).length > 0,
    isNotProd: (state) => state.configMode !== 'production',
    isProd: (state) => state.configMode === 'production',
    isLocal: (state) => state.configMode === 'local',
    isPartsTownUrl: () =>
      window?.location?.hostname?.includes('partstown.com') ||
      window?.location?.hostname?.includes('my-service360.com') ||
      window?.location?.hostname?.includes('service-integrator'),
    isPartsTown: (state) =>
      state.isPartsTownUrl ||
      // localStorage.getItem('is86PtInstance') === 'true' ||
      state.selectedCustomerSetting?.customer?.partnership === 'Parts Town' ||
      state.routeName === 'Logout',
    currentUser: (state) => state.userInfo,
    currentUserId: (state) => state.userInfo?.id,
    currentUserVendorId: (state) => state.userInfo?.vendors?.[0]?.id,
    hasAccessToMultipleCustomers: (state) =>
      state.userInfo.customerSettings?.filter(
        (cs) => cs.customer.status !== 'Churned',
      )?.length > 1,
    customerSettings: (state) => state.userInfo.customerSettings,
    selectedCustomerSetting: (state) => {
      const customerStore = useCustomerStore();
      const setting = state.customerSettings?.find(
        (cs) => cs.customerId === customerStore.selectedCustomer?.id,
      );
      return setting;
    },
    // leaving this hardcoded first customer setting because it is only used for initial setup/login
    currentUserCustomerId: (state) =>
      state.userInfo?.customerSettings?.[0]
        ? state.userInfo?.customerSettings?.[0].customerId
        : null,
    canAccessRequestReporting: (state) =>
      !state.selectedCustomerSetting?.customer?.isSelfService,
    canAccessInvoiceReporting: (state) =>
      state.selectedCustomerSetting?.customer?.canAccessInvoiceReporting,
    canAccessInsightsReporting: (state) =>
      state.selectedCustomerSetting?.customer?.canAccessInsightsReporting ||
      state.selectedCustomerSetting?.customer?.isSelfService,
    currentUserRoles: (state) =>
      state.userInfo?.sspRoles?.map((r) => r.sspRole) ?? [],
    isBohUser: (state) => state.userInfo?.isBohUser,
    isLevelTwoOrUp: (state) => state.userInfo.highestRoleRank <= 2,
    gmAndAboveUser: (state) => state.userInfo.highestRoleRank <= 4,
    isInternalTechUser: (state) =>
      !!state.currentUserRoles.find((role) => role === 'Internal Technician'),
    customerRolesUserCanEdit: (state) => {
      const userRank = state.userInfo.highestRoleRank;
      const mappedRoles = Object.entries(state.rolesAndRanks)
        // eslint-disable-next-line no-unused-vars
        ?.filter(([key, value]) => value.userTypes.includes('person'))
        ?.map(([key, value]) => ({
          label: key,
          value: key,
          disabled: value.rank < userRank,
        }));
      return mappedRoles;
    },
    vendorRolesUserCanEdit: (state) => {
      const userRank = state.userInfo.highestRoleRank;
      const mappedRoles = Object.entries(state.rolesAndRanks)
        // eslint-disable-next-line no-unused-vars
        ?.filter(([key, value]) => value.userTypes.includes('vendor'))
        ?.map(([key, value]) => ({
          label: key,
          value: key,
          disabled: value.rank < userRank,
        }));
      return mappedRoles;
    },
    vendorOnlyUser(state) {
      return (
        // leaving this hardcoded first customer setting
        state.userInfo?.customerSettings?.[0] == null &&
        state.userInfo?.vendors.length
      );
    },
    canViewDashboard: (state) => {
      const customerStore = useCustomerStore();
      return (
        (state.gmAndAboveUser || state.isInternalTechUser) &&
        !customerStore.isSelfServiceCustomer &&
        !state.vendorOnlyUser
      );
    },
  },
});
