import { defineStore } from 'pinia';
import { getLocationsForUser } from '@/service/location.service';

export const useLocationStore = defineStore('location', {
  state: () => ({
    locations: [],
    locationsExpiration: 0,
  }),
  actions: {
    async getLocations() {
      const msFromEpoch = Date.now();
      if (msFromEpoch > this.locationsExpiration) {
        const locations = await getLocationsForUser(false);
        this.locations = locations;
        this.locationsExpiration = msFromEpoch + 1000 * 60; // 1 minute
      } else {
        console.log(
          `Location data is still fresh. Expires in ${
            (this.locationsExpiration - msFromEpoch) / 1000
          } seconds`,
        );
      }
    },
  },
  getters: {
    hasLocationWithPmEnabled: (state) =>
      !!state.locations.filter((l) => l.pmEnabled)?.length,
  },
});
