import Vue from 'vue';
import Vuex from 'vuex';
import { cloneDeep } from 'lodash';

import RequestModule from '@/store/request.module';
import RequestSSPVendorModule from '@/store/ssp-vendor/request.ssp-vendor.module';
import StaticImagesModule from '@/store/static-images.module';
import TaskSSPVendorModule from '@/store/ssp-vendor/task.ssp-vendor.module';
import RequestIntakeMagicLinkModule from '@/store/token-pages/request-intake-magic-link/request-intake.magic-link-request-intake.module';
import AuthRequestIntakeMagicLinkModule from '@/store/ssp-vendor/auth.ssp-vendor.module';
import { useSnackbarStore } from '@/stores/snackbarStore';

Vue.use(Vuex);

export const initialStoreModules = {
  request: RequestModule,
  requestSSPVendor: RequestSSPVendorModule,
  staticImages: StaticImagesModule,
  taskSSPVendor: TaskSSPVendorModule,
  requestIntakeMagicLink: RequestIntakeMagicLinkModule,
  authSSP: AuthRequestIntakeMagicLinkModule,
};

export default new Vuex.Store({
  state: {
    loginProviders: [
      {
        name: 'Google',
        icon: 'mdi-google',
        color: '#DB4437',
      },
      {
        name: 'Microsoft',
        icon: 'mdi-microsoft',
        color: '#00A4EF',
      },
      {
        name: 'Apple',
        icon: 'mdi-apple',
        color: '#000000',
      },
      {
        name: 'LinkedIn',
        icon: 'mdi-linkedin',
        color: '#0073b1',
      },
      {
        name: 'Facebook',
        icon: 'mdi-facebook',
        color: '#1877F2',
      },
    ],
    appLoading: false,
    scrollToRequest: {
      currentTicketNumber: null,
      previousTicketNumber: null,
    },
  },
  mutations: {
    resetState(state) {
      Object.entries(initialStoreModules).forEach(([key, value]) => {
        state[key] = cloneDeep(value.state);
      });
    },
    SET_APP_LOADING(state, value) {
      state.appLoading = value;
    },
    SET_SCROLL_TO_TICKET_NUMBER(
      state,
      { currentTicketNumber, previousTicketNumber },
    ) {
      state.scrollToRequest.currentTicketNumber = currentTicketNumber;
      state.scrollToRequest.previousTicketNumber = previousTicketNumber;
    },
    CLEAR_SCROLL_TO_TICKET_NUMBER(state) {
      state.scrollToRequest.currentTicketNumber = null;
      state.scrollToRequest.previousTicketNumber = null;
    },
  },
  actions: {
    copyToClipboard(context, thingToCopy) {
      navigator.clipboard.writeText(thingToCopy);
      const snackbarStore = useSnackbarStore();
      snackbarStore.customMessage(`"${thingToCopy}" copied to clipboard.`);
    },
    setAppLoading(context, isLoading) {
      context.commit('SET_APP_LOADING', isLoading);
    },
    setScrollToTicketNumber(
      context,
      { currentTicketNumber, previousTicketNumber },
    ) {
      context.commit('SET_SCROLL_TO_TICKET_NUMBER', {
        currentTicketNumber,
        previousTicketNumber,
      });
    },
    clearScrollToTicketNumber(context) {
      context.commit('CLEAR_SCROLL_TO_TICKET_NUMBER');
    },
  },
  modules: cloneDeep(initialStoreModules),
});
