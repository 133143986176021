import esApiV3 from './esapiv3.service';
import exceptionService from './exception.service';

const api = {
  myTasksByQuery: () => 'tasks/self',
  taskById: (id) => `tasks/${id}`,
  completeTask: (id) => `tasks/${id}/complete`,
  taskShareRecipients: (id) => `tasks/${id}/share/recipients`,
  shareTask: (id) => `tasks/${id}/share`,
  savings: (id) => `tasks/${id}/savings`,
};

export async function getMyTasksByQuery(query) {
  try {
    const res = await esApiV3.get(api.myTasksByQuery(), {
      params: { ...query },
    });
    return res.data;
  } catch (ex) {
    exceptionService.showCustomError(
      ex,
      'An error occurred while trying to get tasks',
    );
  }
}

export async function getTaskById(id) {
  try {
    const res = await esApiV3.get(api.taskById(id));
    return res.data;
  } catch (ex) {
    exceptionService.showCustomError(
      ex,
      'An error occurred while trying to get task',
    );
  }
}

export async function completeTask(id, data) {
  try {
    const res = await esApiV3.post(api.completeTask(id), data);
    return res.data;
  } catch (ex) {
    exceptionService.showCustomError(
      ex,
      'An error occurred while trying to complete task',
    );
  }
}

export async function getTaskShareRecipients(id) {
  try {
    const res = await esApiV3.get(api.taskShareRecipients(id));
    return res.data;
  } catch (ex) {
    exceptionService.showCustomError(
      ex,
      'An error occurred while trying to get recipients for sharing task',
    );
  }
}

export async function shareTask(id, payload) {
  try {
    const res = await esApiV3.post(api.shareTask(id), payload);
    return res.data;
  } catch (ex) {
    exceptionService.showCustomError(
      ex,
      'An error occurred while trying to share task',
    );
  }
}

export async function getSavings(id) {
  try {
    const res = await esApiV3.get(api.savings(id));
    return res.data;
  } catch (ex) {
    exceptionService.showCustomError(
      ex,
      'An error occurred while trying to get savings',
    );
  }
}
