import { defineStore } from 'pinia';
import { getCustomerForUser, updateCustomer } from '@/service/customer.service';
import userProfileService from '@/service/user-profile.service';
import { useAuthStore } from './authStore';

export const useCustomerStore = defineStore('customer', {
  state: () => ({
    customer: null,
    selectedCustomer: null,
    customerExpiration: 0,
    tags: [],
    status: Object.freeze({
      LIVE: 'Live',
      PROSPECT: 'Prospect',
      ONBOARDING: 'Onboarding',
      CHURNED: 'Churned',
    }),
    activePeople: [],
    archivedPeople: [],
  }),
  actions: {
    async updateCustomer({ customerId, patch }) {
      const results = await updateCustomer(customerId, patch);
      this.customer = results;
      if (this.selectedCustomer.id === results.id) {
        this.selectedCustomer = results;
      }
    },
    async setCustomerToView(customer) {
      this.selectedCustomer = customer;
    },
    async getCustomer() {
      const authStore = useAuthStore();
      const customerId = authStore.currentUserCustomerId;
      if (customerId == null) {
        console.log('customerId not found on user');
        return;
      }
      const msFromEpoch = Date.now();
      if (msFromEpoch > this.customerExpiration) {
        const results = await getCustomerForUser();
        this.customer = results;
        this.selectedCustomer = results;
        this.tags = (results.locationGroups || []).sort((a, b) =>
          a.name.localeCompare(b.name),
        );
        this.customerExpiration = msFromEpoch + 1000 * 60; // 1 minute
      } else {
        console.log(
          `Customer data is still fresh. Expires in ${
            (this.customerExpiration - msFromEpoch) / 1000
          } seconds`,
        );
      }
    },
    async getCustomerPeople() {
      const results =
        (await userProfileService.getPeople({
          withDeleted: true,
        })) || [];
      this.activePeople = results.filter((r) => !r.deletedAt);
      this.archivedPeople = results.filter((r) => r.deletedAt);
    },
  },
  getters: {
    accountManagerEmail: (state) => state.customer?.accountManager?.email,
    isSelfServiceCustomer: (state) => state.selectedCustomer?.isSelfService,
    selfServiceWithFullPm: (state) =>
      state.selectedCustomer?.selfServiceIncludesFullPm,
  },
});
