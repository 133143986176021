import axios from 'axios';
import router from '@/router';
import config from '@/config';
import authService from '@/service/auth.service';

const ALLOW_AUTH_SKIP = ['/request-intake-magic-link'];

const esApiV3 = axios.create({
  baseURL: new URL('v3/ssp/', config.apiUrl).toString(),
  withCredentials: true,
});

esApiV3.interceptors.request.use(
  (request) => {
    request.headers['current-page'] = window.location.pathname;

    const authToken = authService.getAuthToken();
    if (authToken) {
      request.headers.Authorization = `Bearer ${authToken}`;
    }
    const impersonatedUserId = authService.getImpersonatedUserId();
    if (impersonatedUserId) {
      request.headers['x-impersonate-user-id'] = impersonatedUserId;
    }
    const selectedCustomerId = authService.getSelectedCustomerId();
    if (selectedCustomerId) {
      request.headers['x-selected-customer-id'] = selectedCustomerId;
    }

    return request;
  },
  (error) => Promise.reject(error),
);

let attemptingRefresh = false;
esApiV3.interceptors.response.use(
  (response) => {
    try {
      const is86PtInstance = response.headers && response.headers['x-is-86-pt'];
      if (is86PtInstance && is86PtInstance.toLowerCase() === 'true') {
        try {
          localStorage.setItem('is86PtInstance', true);
        } catch (e) {
          console.log('Error setting localStorage item for 86/PT instance.', e);
        }
        return response;
      }
    } catch (e) {
      console.log(
        'Error processing response headers. Using default of FALSE.',
        e,
      );
    }
    try {
      localStorage.setItem('is86PtInstance', false);
    } catch (e) {
      console.log('Error setting localStorage item for 86/PT instance.', e);
    }
    return response;
  },
  async (error) => {
    if (
      !attemptingRefresh &&
      error?.response?.status === 401 &&
      !error?.config?.url?.includes('auth/token')
    ) {
      if (ALLOW_AUTH_SKIP.includes(error.config.headers['current-page']))
        return;

      attemptingRefresh = true;
      try {
        await axios.post(
          'auth/token',
          {
            app: 'ssp',
            grantType: 'refresh_token',
          },
          {
            baseURL: new URL('v3/', config.apiUrl).toString(),
            withCredentials: true,
          },
        );
        window.location.reload();
        return;
      } catch (e) {
        console.log('Refresh error', e);
      }
      attemptingRefresh = false;
      await authService.revokeAccessToken();
      authService.clearAuthToken();
      router.push({ name: 'Login' });
    }
    return Promise.reject(error);
  },
);

export default esApiV3;
