export class Pagination {
  constructor(query, limit, func) {
    this.query = query;
    this.limit = limit;
    this.function = func;
    this.offset = 0;
    this.count = 0;
    this.items = [];
    this.loading = false;
    this.result = null;
  }

  async getItems() {
    if ((this.offset < this.count || this.count === 0) && !this.loading) {
      this.loading = true;
      const result = await this.function({
        ...this.query,
        limit: this.limit,
        offset: this.offset,
      });
      this.result = result;
      this.items = [...this.items, ...result.data];
      this.count = result.count;
      this.offset += this.limit;
      this.loading = false;
    }
  }

  setQuery(query) {
    this.query = query;
    this.offset = 0;
    this.items = [];
    this.count = 0;
  }

  async refreshItemById(id) {
    const index = this.items.findIndex((item) => item.id === id);
    if (index < 0) return;

    if (!this.loading) {
      this.loading = true;
      const [item, counts] = await Promise.all([
        this.function({ id, ...this.query, limit: 1 }),
        this.function({ ...this.query, limit: 1 }),
      ]);
      if (item.data.length > 0) {
        this.items[index] = item.data[0];
      } else {
        this.items.splice(index, 1);
      }
      this.count = counts.count;
      this.result = counts;
      this.loading = false;
    }
  }
}
