import {
  getRequest,
  uploadAttachment,
} from '@/service/vendor-auto-communication.service';
import { useSnackbarStore } from '@/stores/snackbarStore';

const module = {
  namespaced: true,
};

module.state = {
  requestId: null,
  requestAttachments: [],
};

module.mutations = {
  SET_REQUEST_ID(state, request) {
    state.requestId = request;
  },
  ADD_ATTACHMENT(state, attachment) {
    state.requestAttachments.push(attachment);
  },
};

module.actions = {
  async getRequestId({ commit }, token, recaptchaToken) {
    const result = await getRequest(token, recaptchaToken);
    commit('SET_REQUEST_ID', result.id);
  },
  async uploadAttachment(
    { commit, state, rootState },
    { attachment, attachmentType, taskId },
  ) {
    if (state.requestId) {
      const [response, code] = await uploadAttachment(
        rootState.authSSP.token,
        rootState.authSSP.recaptcha,
        state.requestId,
        attachment,
        attachmentType,
        taskId,
      );

      if (code === 202) {
        const snackbarStore = useSnackbarStore();
        snackbarStore.customMessage(
          `${attachmentType} already uploaded for request`,
        );
      } else {
        commit('ADD_ATTACHMENT', response);
      }
    }
  },
};

module.getters = {};

export default module;
